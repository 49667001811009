import React, { useState, useEffect } from "react"
import { Block } from "baseui/block"
import { Input } from "baseui/input"
import { Search } from "baseui/icon"
import { H5 } from "baseui/typography"
import { Button } from "baseui/button"
import { Plus } from "baseui/icon"
import { Tabs, Tab } from "baseui/tabs"
import withDashboard from "hoc/withDashboard"
import withAdminNavigate from "hoc/withAdminNavigate"
import ReportBox from "components/ReportBox"
import ManageUserModal from "components/ManageUserModal"
import { Spinner } from "baseui/spinner"
import {
  getUserSummary,
  updateUserTab,
  getStudent,
  getProfessor,
  updateStudentPage,
  updateProfessorPage,
  updateAdminPage,
  editRoleUser,
  insertUser,
  getAdmin,
} from "../actions/dashboard"
import UserTable from "components/UserTable"
import ErrorModal from "components/ErrorModal"

const UserPage = ({ dashboardState, dashboardDispatch }) => {
  const {
    userPage,
    studentPage,
    userSummary,
    activeUserTab,
    students,
    professors,
    admins,
    professorPage,
    adminPage,
    studentKeyword,
    professorKeyword,
    adminKeyword,
    studentCount,
    adminCount,
    professorCount,
  } = dashboardState
  const limit = 8
  useEffect(() => {
    if (students.length === 0) {
      getStudent(dashboardDispatch, 0, limit, "")
    }
    if (professors.length === 0) {
      getProfessor(dashboardDispatch, 0, limit, "")
    }
    if (admins.length === 0) {
      getAdmin(dashboardDispatch, 0, limit, "")
    }
    if (!userSummary) {
      getUserSummary(dashboardDispatch)
    }
  }, [])

  useEffect(() => {
    if (activeUserTab === "0") {
      setKetWord(professorKeyword)
    } else {
      setKetWord(studentKeyword)
    }
  }, [activeUserTab])

  const [showModal, setShowModal] = useState(false)
  const [keyWord, setKetWord] = useState("")
  const [loading, setLoading] = useState(false)
  const [editUser, setEditUser] = useState(null)
  const [showError, setShowError] = useState(false)
  const [errorDetail, setErrorDetail] = useState({ header: "", text: "" })

  const studentWindow = () => {
    const min = (studentPage - 1) * limit
    return students.slice(min, min + limit)
  }

  const professorWindow = () => {
    const min = (professorPage - 1) * limit
    return professors.slice(min, min + limit)
  }

  const adminWindow = () => {
    const min = (adminPage - 1) * limit
    return admins.slice(min, min + limit)
  }

  const handlePageStudentChange = nextPage => {
    if (
      nextPage > userPage &&
      nextPage * limit > students.length &&
      students.length < studentCount
    ) {
      setLoading(true)
      getStudent(
        dashboardDispatch,
        students.length,
        nextPage * limit - students.length,
        studentKeyword
      ).then(() => setLoading(false))
    }
    if (nextPage < 1) {
      return
    }
    if (nextPage > Math.ceil(studentCount / limit)) {
      return
    }
    updateStudentPage(dashboardDispatch, nextPage)
  }

  const handlePageProfessorChange = nextPage => {
    if (
      nextPage > professorPage &&
      nextPage * limit > professors.length &&
      professors.length < professorCount
    ) {
      setLoading(true)
      getProfessor(
        dashboardDispatch,
        professors.length,
        nextPage * limit - professors.length,
        professorKeyword
      ).then(() => setLoading(false))
    }
    if (nextPage < 1) {
      return
    }
    if (nextPage > Math.ceil(professorCount / limit)) {
      return
    }
    updateProfessorPage(dashboardDispatch, nextPage)
  }

  const handlePageAdminChange = nextPage => {
    if (
      nextPage > adminPage &&
      nextPage * limit > admins.length &&
      admins.length < adminCount
    ) {
      setLoading(true)
      getAdmin(
        dashboardDispatch,
        admins.length,
        nextPage * limit - admins.length,
        adminKeyword
      ).then(() => setLoading(false))
    }
    if (nextPage < 1) {
      return
    }
    if (nextPage > Math.ceil(adminCount / limit)) {
      return
    }
    updateAdminPage(dashboardDispatch, nextPage)
  }

  const resetStudent = () => {
    getStudent(dashboardDispatch, 0, limit, studentKeyword)
    updateStudentPage(dashboardDispatch, 1)
  }

  const resetProfessor = () => {
    getProfessor(dashboardDispatch, 0, limit, professorKeyword)
    updateProfessorPage(dashboardDispatch, 1)
  }

  const resetAdmin = () => {
    getAdmin(dashboardDispatch, 0, limit, professorKeyword)
    updateAdminPage(dashboardDispatch, 1)
  }
  return (
    <Block width="100%">
      <H5 marginTop="0px" marginBottom="10px">
        User Management
      </H5>
      <Block display="flex" justifyContent="space-between">
        <ReportBox
          title={"ทั้งหมด"}
          value={userSummary && userSummary.total}
          width={"28%"}
          type={"userGroup"}
        />
        <ReportBox
          title={"อาจารย์/บุคลากร"}
          value={userSummary && userSummary.professor}
          width={"28%"}
          type={"professor"}
        />
        <ReportBox
          title={"นักศึกษา"}
          value={userSummary && userSummary.student}
          width={"28%"}
          type={"student"}
        />
      </Block>
      <Block marginTop="20px" backgroundColor="white" padding="20px">
        <Block
          display="flex"
          marginBottom="25px"
          justifyContent="space-between"
        >
          <Input
            name="search"
            startEnhancer={<Search size="18px" />}
            overrides={{
              Root: {
                style: {
                  width: "350px",
                },
              },
            }}
            value={keyWord}
            onChange={e => setKetWord(e.target.value)}
            onKeyPress={event => {
              const getData = keyWord =>
                activeUserTab === "0"
                  ? getProfessor(dashboardDispatch, 0, limit, keyWord)
                  : getStudent(dashboardDispatch, 0, limit, keyWord)
              const setPage = () =>
                activeUserTab === "0"
                  ? updateProfessorPage(dashboardDispatch, 1)
                  : updateStudentPage(dashboardDispatch, 1)
              if (event.key === "Enter") {
                if (keyWord) {
                  getData(keyWord).catch(() => {
                    setShowError(true)
                    setErrorDetail({
                      header: "มีข้อผิดพลาด",
                      text: `ไม่พบ ${
                        activeUserTab === "0" ? "อาจารย์" : "นักศึกษา"
                      } ที่ค้นหา`,
                    })
                  })
                } else {
                  getData("")
                }
                setPage()
              }
            }}
          />
          <Button
            onClick={() => setShowModal(true)}
            startEnhancer={() => <Plus size={24} />}
          >
            เพิ่มใหม่
          </Button>
        </Block>
        <Tabs
          onChange={({ activeKey }) => {
            updateUserTab(dashboardDispatch, activeKey)
          }}
          overrides={{
            TabBar: {
              style: {
                paddingLeft: "0px",
                paddingRight: "0px",
                height: "48px",
              },
            },
            TabContent: {
              style: {
                paddingLeft: "0px",
                paddingRight: "0px",
              },
            },
          }}
          activeKey={activeUserTab}
        >
          <Tab title="Admin">
            {admins.length > 0 && adminCount && (
              <UserTable
                users={admins}
                updateUserPage={nextPage => handlePageAdminChange(nextPage)}
                page={adminPage}
                limit={limit}
                size={adminCount}
                dataToShow={adminWindow()}
                loading={loading}
                editUser={user => {
                  setShowModal(true)
                  setEditUser(user)
                }}
              />
            )}
          </Tab>
          <Tab title="Professor">
            {professors.length > 0 && professorCount && (
              <UserTable
                users={professors}
                updateUserPage={nextPage => handlePageProfessorChange(nextPage)}
                page={professorPage}
                limit={limit}
                size={professorCount}
                dataToShow={professorWindow()}
                loading={loading}
                editUser={user => {
                  setShowModal(true)
                  setEditUser(user)
                }}
              />
            )}
            {professors.length === 0 && (
              <Block display="flex" justifyContent="center">
                <Spinner
                  size="60px"
                  style={{ marginTop: 100, marginBottom: 100 }}
                  overrides={{
                    ActivePath: {
                      style: ({ $theme }) => ({ fill: $theme.colors.primary }),
                    },
                  }}
                />
              </Block>
            )}
          </Tab>
          <Tab title="Student">
            {students.length > 0 && studentCount && (
              <UserTable
                users={students}
                updateUserPage={nextPage => handlePageStudentChange(nextPage)}
                page={studentPage}
                limit={limit}
                size={studentCount}
                dataToShow={studentWindow()}
                loading={loading}
                editUser={user => {
                  setShowModal(true)
                  setEditUser(user)
                }}
              />
            )}
          </Tab>
        </Tabs>
        <ManageUserModal
          showModal={showModal}
          onClose={() => {
            setShowModal(false)
            setEditUser(null)
          }}
          resetData={() => {
            resetStudent()
            resetProfessor()
            resetAdmin()
            getUserSummary(dashboardDispatch)
          }}
          editUser={editUser}
          insertUser={insertUser}
          editRoleUser={editRoleUser}
        />
        <ErrorModal
          open={showError}
          onClose={() => setShowError(false)}
          header={errorDetail.header}
          text={errorDetail.text}
        />
      </Block>
    </Block>
  )
}

export default withAdminNavigate(withDashboard(UserPage))
