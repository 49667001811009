import React from "react"
import moment from "moment"
import { get, isInteger } from "lodash"
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table"
import { Edit16 } from "@carbon/icons-react"
import { Block } from "baseui/block"
import { Spinner } from "baseui/spinner"
import { Pagination } from "baseui/pagination"
import { Label3, Paragraph4 } from "baseui/typography"

const UserTable = ({
  users,
  updateUserPage,
  page,
  limit,
  dataToShow,
  size,
  loading,
  editUser,
}) => {
  const disabledEdit = user => {
    if (user.role === "student") {
      const email = user.email.split("@")[0]
      if (isInteger(+email)) return true
      return false
    }
    return false
  }

  return (
    <Block>
      <StyledTable>
        <StyledHead>
          <StyledHeadCell style={{ maxWidth: "10%", textAlign: "center" }}>
            <Label3 margin="auto">วันที่สร้าง</Label3>
          </StyledHeadCell>
          <StyledHeadCell style={{ maxWidth: "30%", textAlign: "center" }}>
            <Label3 margin="auto">อีเมล</Label3>
          </StyledHeadCell>
          <StyledHeadCell style={{ maxWidth: "10%", textAlign: "center" }}>
            <Label3 margin="auto">ประเภท</Label3>
          </StyledHeadCell>
          <StyledHeadCell style={{ maxWidth: "15%", textAlign: "center" }}>
            <Label3 margin="auto">เบอร์โทรศัพท์</Label3>
          </StyledHeadCell>
          <StyledHeadCell style={{ maxWidth: "20%", textAlign: "center" }}>
            <Label3 margin="auto">จำนวนงานที่ประกาศ / สมัคร</Label3>
          </StyledHeadCell>
          <StyledHeadCell style={{ maxWidth: "15%", textAlign: "center" }}>
            <Label3 margin="auto">แก้ไข</Label3>
          </StyledHeadCell>
        </StyledHead>
        {users.length > 0 && (
          <StyledBody>
            {loading && (
              <Block display="flex" justifyContent="center">
                <Spinner
                  size="60px"
                  style={{ marginTop: 100, marginBottom: 100 }}
                  overrides={{
                    ActivePath: {
                      style: ({ $theme }) => ({ fill: $theme.colors.primary }),
                    },
                  }}
                />
              </Block>
            )}
            {dataToShow.map((item, index) => (
              <StyledRow
                style={{
                  paddingTop: 10,
                  PaddingBottom: 10,
                  backgroundColor:
                    (index + 1) % 2 === 0
                      ? "rgba(247, 247, 247, 0.5)"
                      : "transparent",
                }}
              >
                <StyledCell style={{ maxWidth: "10%" }}>
                  {get(item, "created_at", null) && (
                    <Paragraph4>
                      {moment(item.created_at).format("D MMM YYYY")}
                    </Paragraph4>
                  )}
                </StyledCell>
                <StyledCell
                  style={{ maxWidth: "30%", wordBreak: "break-word" }}
                >
                  {get(item, "email", null) && (
                    <Paragraph4>{item.email}</Paragraph4>
                  )}
                </StyledCell>
                {get(item, "role", null) && (
                  <StyledCell style={{ maxWidth: "10%" }}>
                    {item.role}
                  </StyledCell>
                )}
                <StyledCell style={{ maxWidth: "15%" }}>
                  {item.phone || "-"}
                </StyledCell>
                <StyledCell
                  style={{ maxWidth: "20%", justifyContent: "center" }}
                >
                  {item.activity}
                </StyledCell>
                <StyledCell style={{ maxWidth: "15%" }}>
                  <Block
                    display="flex"
                    style={{
                      cursor: !disabledEdit(item) ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      !disabledEdit(item) && editUser(item)
                    }}
                  >
                    <Edit16
                      style={{ fill: !disabledEdit(item) ? "#ff7c1b" : "grey" }}
                    />
                    <Paragraph4
                      marginTop="0px"
                      marginBottom="0px"
                      marginLeft="5px"
                      color={!disabledEdit(item) ? "#ff7c1b" : "grey"}
                    >
                      แก้ไข
                    </Paragraph4>
                  </Block>
                </StyledCell>
              </StyledRow>
            ))}
          </StyledBody>
        )}
      </StyledTable>
      {users.length > 0 && (
        <Block marginTop="25px" display="flex" justifyContent="center">
          <Pagination
            numPages={Math.ceil(size / limit)}
            currentPage={page}
            onPageChange={({ nextPage }) => updateUserPage(nextPage)}
          />
        </Block>
      )}
    </Block>
  )
}

export default UserTable
