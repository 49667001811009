import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Input } from 'baseui/input'
import { Label1 } from 'baseui/typography'
import { FormControl } from 'baseui/form-control'
import { Select } from 'baseui/select'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal'

const options = [
  { id: 'admin', type: 'Admin' },
  { id: 'professor', type: 'Professor' },
  { id: 'student', type: 'Student' },
]

const ManageUserModal = ({
                           onClose,
                           showModal,
                           editUser,
                           editRoleUser,
                           resetData,
                           insertUser,
                         }) => {
  const [ email, setEmail ] = useState('')
  const [ emailError ] = useState(false)
  const [ userType, setUserType ] = React.useState([options[0]])
  const [ loading, setLoading ] = useState(false)
  useEffect(() => {
    if (editUser) {
      setUserType([options.find(option => option.id === editUser.role) ])
      setEmail(editUser.email)
    }
  }, [ editUser ])

  return (
    <Modal
      onClose={() => onClose()}
      isOpen={showModal}
      overrides={{
        Root: {
          style: () => {
            return {
              zIndex: 'auto',
            }
          },
        },
      }}
    >
      <ModalHeader>{ email ? 'แก้ไขข้อมูล User' : 'เพิ่ม User ใหม่'} </ModalHeader>
      <ModalBody>
        <FormControl label="อีเมล">
          <Input
            onChange={event => {
              setEmail(event.target.value)
            }}
            placeholder="email"
            value={editUser ? editUser.email : email}
            error={emailError}
            disabled={editUser}
          />
        </FormControl>
        {emailError && (
          <Label1 style={{ fontWeight: 'bold', color: 'red', marginTop: 20 }}>
            กรุณากรอกอีเมลให้ถูกต้อง
          </Label1>
        )}
        <FormControl label="ประเภท">
          <Select
            options={options}
            clearable={false}
            labelKey="type"
            valueKey="id"
            value={userType}
            placeholder="Select user type"
            onChange={params => setUserType(params.value)}
            overrides={{
              Dropdown: {
                style: { zIndex: 100, position: 'relative' },
              },
            }}
            searchable={false}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => {
            onClose()
            setEmail('')
          }}
          kind={'minimal'}
        >
          ยกเลิก
        </ModalButton>
        <ModalButton
          disabled={
            !email ||
            !Yup.string()
              .email()
              .isValidSync(email)
          }
          autoFocus
          onClick={() => {
            if (editUser) {
              setLoading(true)
              editRoleUser({ id: editUser.id, role: userType[0].id }).then(
                () => {
                  setLoading(false)
                  resetData()
                  onClose()
                  setEmail('')
                  setUserType([ { id: 'professor', type: 'professor' } ])
                }
              )
            } else {
              setLoading(true)
              insertUser({ email, role: userType[0].id }).then(() => {
                setLoading(false)
                resetData()
                onClose()
                setEmail('')
                setUserType([ { id: 'professor', type: 'professor' } ])
              })
            }
          }}
          isLoading={loading}
        >
          ตกลง
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default ManageUserModal
